// @flow

import { h, Component } from "preact";
import styles           from "./styles.scss";
import classNames       from "classnames";
import { Field }        from "formaggio";

import FloatLabel from "components/FloatLabel";

type Props = {
  class?    : string,
  readOnly  : boolean,
  isVirtual?: boolean,
};

export default class CheckoutForm extends Component {
  props: Props;

  render({ readOnly, isVirtual }: Props, state: any, { t }: Context) {
    return (
      <fieldset
          class={classNames(
            this.props["class"],
            styles.block
          )}>

        <div class={styles.row}>
          <div class={styles.column}>
            <Field
                component={FloatLabel}
                name="billingAddress.firstname"
                modifiers="fill underline"
                readOnly={readOnly}
                label={t("ADDRESS.FIRSTNAME.LABEL")}
            />
          </div>

          <div class={styles.column}>
            <Field
                component={FloatLabel}
                name="billingAddress.lastname"
                modifiers="fill underline"
                readOnly={readOnly}
                label={t("ADDRESS.LASTNAME.LABEL")}
            />
          </div>
        </div>

        {!isVirtual && (
          <div>
            <div class={styles.row}>
              <div class={styles.column}>
                <Field
                    component={FloatLabel}
                    name="billingAddress.street.0"
                    modifiers="fill underline"
                    readOnly={readOnly}
                    label={t("ADDRESS.STREET.LABEL")}
                />
              </div>

              <div class={styles.column}>
                <Field
                    component={FloatLabel}
                    name="billingAddress.street.1"
                    modifiers="fill underline"
                    readOnly={readOnly}
                    label={t("ADDRESS.STREET_2.LABEL")}
                />
              </div>
            </div>

            <div class={styles.row}>
              <div class={styles.column}>
                <Field
                    component={FloatLabel}
                    name="billingAddress.postcode"
                    modifiers="fill underline"
                    readOnly={readOnly}
                    label={t("ADDRESS.POSTCODE.LABEL")}
                    pattern={t("ADDRESS.POSTCODE.PATTERN")}
                />
              </div>

              <div class={styles.column}>
                <Field
                    component={FloatLabel}
                    name="billingAddress.city"
                    modifiers="fill underline"
                    readOnly={readOnly}
                    label={t("ADDRESS.CITY.LABEL")}
                />
              </div>
            </div>
          </div>
        )}

        <div class={styles.row}>
          {!isVirtual && <div class={styles.column}>
            <Field
                component={FloatLabel}
                name="billingAddress.telephone"
                modifiers="fill underline"
                readOnly={readOnly}
                type="tel"
                label={t("ADDRESS.TELEPHONE.LABEL")}
                pattern={t("ADDRESS.TELEPHONE.PATTERN")}
            />
          </div>}

          <div class={styles.column}>
            <Field
                component={FloatLabel}
                name="email"
                modifiers="fill underline"
                readOnly={readOnly}
                type="email"
                label={t("ADDRESS.EMAIL.LABEL")}
                pattern={t("ADDRESS.EMAIL.PATTERN")}
            />
          </div>
        </div>
      </fieldset>
    );
  }
}
