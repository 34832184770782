import {
         ADDING as CART_ADDING,
         REMOVING as CART_REMOVING
} from "store-reducers/cart";
import {
         SUCCESS as CHECKOUT_SUCCESS,
         LOADED as CHECKOUT_VIEW
} from "store-reducers/checkout";
import {
         PENDING as CMS_PAGES_PENDING,
         load as loadCmsPages
} from "store-reducers/cmsPages";
import { Component, h }          from "preact";
import {
         ConnectedRouter,
         LOCATION_CHANGE,
         routerMiddleware
}       from "react-router-redux";
import {
         Redirect,
         Route,
         Switch
}              from "react-router";
import {
         applyMiddleware,
         compose,
         createStore
}       from "redux";
import enhanceWithGA, {
  checkout,
  modifyCart,
  pageView,
  productView,
  purchaseSuccess
} from "redux-enhancer-google-analytics";

import App          from "containers/App";
import Checkout     from "containers/Checkout";
import DemoCategory from "containers/DemoCategory";
import GiftCategory from "containers/GiftCategory";
import Login        from "containers/Login";
import Logout       from "containers/Logout";
import NotFound     from "containers/NotFound";
import OrderSuccess from "containers/OrderSuccess";
import { VIEW_LOADED as PRODUCT_LOADED } from "store-reducers/view";
import RouteView    from "containers/RouteView";
import Translation               from "helpers/translation";
import { actionDelayMiddleware } from "shared-components";
import { closeAll as closeModals } from "store-reducers/modals";
import config                    from "../config.js";
import { connect }               from "preact-redux";
import reducers                  from "../redux";
import { shallowEquals }         from "diskho";
import sideEffectsMiddleware     from "middlewares/sideEffectsMiddleware.js";
import thunk                     from "redux-thunk";
import trackedPromises           from "redux-enhancer-tracked-promises";

export const APP_KEY = "redeem";

export const mkStore = (state, history, client) =>
  createStore(reducers, state, compose(
    applyMiddleware(
      sideEffectsMiddleware,
      thunk.withExtraArgument(client),
      routerMiddleware(history),
      actionDelayMiddleware()
    ),
    trackedPromises(),
    process.browser ? enhanceWithGA({
      accounts:     config.googleAnalytics[state.lang].accounts,
      currencyCode: config.googleAnalytics[state.lang].currencyCode,
      events:       {
        [LOCATION_CHANGE]  : pageView,
        [CHECKOUT_VIEW]    : checkout,
        [CHECKOUT_SUCCESS] : purchaseSuccess,
        [CART_ADDING]      : (ga, action) => { modifyCart(ga, action, "add") },
        [CART_REMOVING]    : (ga, action) => { modifyCart(ga, action, "remove") },
        [PRODUCT_LOADED]   : (ga, action) => {
          if (action.error || ! action.payload || action.payload.type !== "product") {
            return;
          }
          productView(ga, action);
        }
      }
    }) : f => f,
    
    process.env.NODE_ENV !== "production" && typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
  ));

const AuthRoute = ({ component: Component, ...rest }, { store }) => {
  return (
    <Route
        {...rest}
        render={props =>
          store.getState().customer.loggedIn ?
            <Component {...props} /> :
            <Redirect to="/login" />
        } />
  );
};

@connect(state => ({
  cmsPagesPending : state.cmsPages.state === CMS_PAGES_PENDING,
  location        : state.router.location,
  loggedIn        : state.customer.loggedIn,
}), dispatch => ({
  closeModals  : () => dispatch(closeModals()),
  loadCmsPages : () => dispatch(loadCmsPages()),
}))
export class Routes extends Component {
  loadNecessaryData = props => {
    if (props.cmsPagesPending) {
      props.loadCmsPages();
    }
  }

  getChildContext = () => {
    const t = new Translation(this.props.translation);

    return {
      t: t.translate.bind(t),
      hasTranslation: t.hasTranslation.bind(t),
    }
  }

  componentWillMount() {
    this.loadNecessaryData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.loadNecessaryData(nextProps);

    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.props.closeModals();
    }
  }

  shouldComponentUpdate({ location }) {
    return !shallowEquals(location, this.props.location);
  }

  render({ location, loggedIn }) {
    return (
      <App config={config}>
        <Switch location={location}>
          <AuthRoute path="/"                 exact component={GiftCategory} />
          <Route     path="/demo/:urlKey"     exact component={DemoCategory} />
          <Route     path="/login"            exact component={Login} />
          <AuthRoute path="/logout"           exact component={Logout} />
          <AuthRoute path="/checkout"         exact component={Checkout} />
          <AuthRoute path="/checkout/success" exact component={OrderSuccess} />
          <AuthRoute path="/sso"                    component={() => <Redirect to="/" />} />
          <Route     path="/(.+)"                   component={RouteView} />
          <Route                                    component={NotFound} />
        </Switch>
      </App>
    );
  }
}
