// @flow

import { h }             from "preact";
import { PureComponent,
         ScrollTop }     from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import Helmet            from "preact-helmet";
import { connect }       from "preact-redux";
import { Redirect }      from "react-router";
import { parseParams }   from "location-search-string";
import { stutter,
         slideUp,
         slideLeft,
         slideRightOut,
         slideDown,
         slideDownOut,
         fadeIn }        from "helpers/animations";

import { animateLogin,
         pendingAnimation,
         resetAnimationState,
         LOGIN as LOGIN_LEAVING,
         PENDING, } from "redux/animate";

import { login,
         LOGGING_IN } from "redux/customer";

import Button         from "components/Button";
import FloatLabel     from "components/FloatLabel";
import Wrapper        from "components/Wrapper";
import InformationBar from "components/InformationBar";

import AppFooter from "containers/AppFooter";

@connect(
  state => ({
    leaving      : state.animate.state  === LOGIN_LEAVING,
    loggedIn     : state.customer.loggedIn,
    pendingLeave : state.animate.state  === PENDING,
    submitting   : state.customer.state === LOGGING_IN,
    errorCode    : state.customer.data.errorCode === 106 ? null : state.customer.data.errorCode,
  }),
  dispatch => ({
    animateLogin        : () => dispatch(animateLogin()),
    login               : id => dispatch(login(id)),
    resetAnimationState : () => dispatch(resetAnimationState),
    setAnimationPending : () => dispatch(pendingAnimation),
  })
)
export default class Login extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      code: parseParams(props.location.search).code || ""
    }
  }

  handleInput = (event: Event) => {
    this.setState({
      code: event.target.value
    });
  }

  handleSubmit = (event: Event) => {
    event.preventDefault();

    if (this.state.code.length && !this.props.submitting && !this.props.leaving) {
      this.props.setAnimationPending();

      this.props.login({
        code: this.state.code
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loggedIn && !this.props.loggedIn) {
      this.props.animateLogin();
    }

    if (this.props.submitting && !nextProps.submitting && !nextProps.loggedIn) {
      this.props.resetAnimationState();
    }
  }

  render({ errorCode, leaving, loggedIn, pendingLeave, submitting }: Props, { code }: any, { t, hasTranslation }: Context) {
    if (loggedIn && !pendingLeave && !leaving) {
      return <Redirect to="/" />
    }

    return (
      <div class={styles.block}>
        <Wrapper
            element="section"
            class={styles.wrapper}>

          <div
              class={classNames(
                styles.body,
                { [slideDownOut]: leaving }
              )}>

            <h1>
              <span
                  class={slideUp}
                  style={{ display: "inline-block" }}>

                {t("LOGIN.HEADING")}
              </span>
            </h1>

            <div
                style={stutter(2)}
                class={classNames(
                  slideUp,
                  "row--large"
                )}>

              <p>
                {t("LOGIN.TEXT")}
              </p>
            </div>

            <form
                class={styles.form}
                onSubmit={this.handleSubmit}>

              <div
                  style={stutter(3)}
                  class={slideUp}>

                <FloatLabel
                    name="code"
                    id="code"
                    value={code}
                    onInput={this.handleInput}
                    label={t("LOGIN.LABEL")}
                    readOnly={submitting}
                    autoComplete="off"
                    modifiers="fill large underline"
                />
              </div>

              <div
                  style={stutter(4)}
                  class={classNames(
                    styles.submit,
                    slideUp,
                    "row--large",
                  )}>

                <Button
                    type="submit"
                    class={styles.button}
                    disabled={submitting || leaving}
                    modifiers="primary">

                  {submitting || leaving ? t("LOGIN.SUBMITTING") : t("LOGIN.SUBMIT")}
                </Button>

                {errorCode && !submitting ?
                  <InformationBar
                      modifiers="text"
                      class={classNames(
                        styles.error,
                        slideDown
                      )}>

                    {t(`STATUS_CODE.${errorCode}`)}
                  </InformationBar>
                : null}
              </div>
            </form>
          </div>
        </Wrapper>

        <div
            class={classNames(
              styles.background,
              { [slideLeft]     : !leaving,
                [slideRightOut] :  leaving }
            )}
            style={{
              ...stutter(leaving ? 0 : 3),
              backgroundImage: "url('" + t("LOGIN.BACKGROUND") + "')",
              backgroundPosition: t("LOGIN.BACKGROUND_POSITION"),
            }}
        />

        <ScrollTop />

        <Helmet title={t("LOGIN.TITLE")} />
      </div>
    );
  }
}
