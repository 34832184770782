// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import { Link }          from "react-router-dom";

import Logo from "icons/eon.svg";

export const getLogoWithLocale = () => <Logo class={styles.logo} />;

export default function AppLogo(props, { t }: Context) {
  return (
    <Link
        to={t("LOGO.HREF")}
        title={t("LOGO.TITLE")}
        class={classNames(
          props["class"],
          styles.block,
        )}>

      <Logo class={styles.logo} />
    </Link>
  );
}
