// @flow

import { jsonResponse } from "fetch-utilities";
import type { Fetch }   from "fetch-utilities";

import { LOGOUT as CUSTOMER_LOGOUT } from "./customer";

export const CATEGORY_PENDING = "store/giftCategory/CATEGORY_PENDING";
export const CATEGORY_LOADING = "store/giftCategory/CATEGORY_LOADING";
export const CATEGORY_LOADED  = "store/giftCategory/CATEGORY_LOADED";

const initialState = {
  state    : CATEGORY_PENDING,
  category : {},
  products : [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CATEGORY_LOADING:
      return {
        ...state,
        error : false,
        state : action.type
      };

    case CATEGORY_LOADED:
      if (action.error || action.payload && action.payload.error) {
        return {
          ...state,
          error     : true,
          errorData : action.payload,
        };
      }

      return {
        ...state,
        state    : action.type,
        demo     : action.demo,
        category : action.payload.category,
        products : action.payload.products,
      };

    case CUSTOMER_LOGOUT:
      return {
        ...initialState
      };

    default:
      return state;
  }
}

export const load = (id, demo) => (dispatch: Function, _getState: Function, client: Fetch) => {
  dispatch({
    type : CATEGORY_LOADING
  });

  dispatch({
    type    : CATEGORY_LOADED,
    demo    : demo,
    payload : client(`/categories/${id}?limit=100`).then(jsonResponse)
  });
};

const handleError = err => {
  if(!err.httpOk && err.httpStatus === 404) {
    return { ...err, error: true };
  }

  throw Promise.reject(err);
};

export const loadCategoryByKey = (urlKey, demo) => (dispatch: Function, _getState: Function, client: Fetch) => {
  dispatch({
    type : CATEGORY_LOADING
  });

  dispatch({
    type    : CATEGORY_LOADED,
    demo    : demo,
    payload : client(`/route/${urlKey}?limit=100`)
                .then(jsonResponse)
                .then(resp => resp.category ? { ...resp.category } : null)
                .catch(handleError)
  });
};
