// Acquire redeem master
module.exports = {
  host: "localhost",
  port: 9141,
  apiHost: {
    server       : "http://127.0.0.1/api",
    client       : "/api",
    proxyHeaders : ["SSL", "Ssl-Offloaded", "X-Forwarded-Proto", "Host", "X-Real-Ip", "X-Forwarded-For"],
  },
  cspUri: "https://csp-report.browser-intake-datadoghq.eu/api/v2/logs?dd-api-key=pub552607268219ad5b8d2647030dd2f46f&dd-evp-origin=content-security-policy&ddsource=csp-report&ddtags=service%3Ajulklappsvalet-redeem-eon%2Cenv%3Aproduction",
  googleAnalytics: {
    sv_SE: {
      currencyCode: "SEK",
      accounts: ["UA-5572085-111"]
    }
  },
  isProduction: true
};
