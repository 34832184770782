// @flow

import { h }                from "preact";
import { PureComponent }    from "shared-components";
import styles               from "./styles.scss";
import classNames           from "classnames";
import { Link }             from "react-router-dom";
import { connect }          from "preact-redux";
import { hintProduct }      from "store-reducers/product";
import { animateListItem,
         LIST_ITEM }        from "redux/animate";
import { stutter,
         fadeOut,
         shortDuration,
         slideDownOut }     from "helpers/animations";

import InformationBar from "components/InformationBar";
import Button         from "components/Button";

type Props = {
  class?:        string,
  medium?:       boolean,
  full?:         boolean,
  product?:      TProduct,
  shouldAnimate: boolean
};

@connect(
  (state, { product = {} }) => {
    return {
      disabled   : !product.isSalable,
      isSelected : state.animate.data.id === product.id,
      leaving    : state.animate.state   === LIST_ITEM,
    };
  },
  (dispatch, { product }) => ({
    animateListItem : () => dispatch(animateListItem(product)),
    hint            : () => dispatch(hintProduct(product)),
  })
)
export default class ProductCard extends PureComponent {
  props   : Props;
  context : Context;

  handleClick = (event: Event) => {
    if (!this.props.product.isSalable) {
      event.preventDefault();
      return;
    }

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (this.props.shouldAnimate) {
      event.preventDefault();
      this.props.animateListItem();
    } else {
      this.props.hint();
    }
  }

  render({ animate, disabled, medium, full, product, leaving, isSelected, demoMode }: Props, state: any, { t }: Context) {
    if (!product) {
      return (
        <div
            class={classNames(
              this.props["class"],
              styles.block,
              styles.loading
            )}
        />
      );
    }

    const attributes = product.attributes || {};

    return (
      <Link
          itemScope
          itemType="https://schema.org/Product"
          to={product.urlKey && product.isSalable ? `/${product.urlKey}` : ""}
          onClick={this.handleClick}
          itemProp="url"
          style={leaving && isSelected ? stutter(2) : null}
          class={classNames(
            this.props["class"],
            styles.block,
            { [slideDownOut]   : leaving && !isSelected,
              [fadeOut]        : leaving &&  isSelected,
              [shortDuration]  : leaving &&  isSelected,
              [styles.salable] : product.isSalable }
          )}>

        <div class={styles.media}>
          <div class={styles.imageWrap}>
            <picture>
              <source
                  media={styles.bpSmall}
                  srcset={attributes.normal_width_image} />

              <source
                  media={styles.bpMedium}
                  srcset={
                    (full || medium) ?
                      attributes.medium_width_image :
                      attributes.normal_width_image} />

              <source
                  media={styles.bpLarge}
                  srcset={
                    full ?
                      attributes.full_width_image :
                    medium ?
                      attributes.medium_width_image :
                      attributes.normal_width_image} />

              <img
                  alt=""
                  src={
                    full ?
                      attributes.full_width_image :
                    medium ?
                      attributes.medium_width_image :
                      attributes.normal_width_image
                  }
                  class={classNames(
                    {[styles.grayscale]: !product.isSalable}
                  )}
              />
            </picture>
          </div>
          {attributes.julklappsvalet_demo_product === "1" ?
            <div class={styles.banner}>
              <div class={styles.bannerInner}>
                {t("PRODUCT.DEMO")}
              </div>
            </div>
          : null}
        </div>

        <div class={styles.contain}>
          {product.urlKey ?
            <Button
                class={styles.button}
                disabled={!product.isSalable}
                modifiers="ghost slim">

              {t("PRODUCT.READ_MORE")}
            </Button>
          : null}

          <div class={styles.information}>
            <h3 class={styles.name}
                itemProp="name"
                title={product.name}>

              {product.name}
            </h3>

            {attributes.manufacturer ?
              <p  class={styles.type}
                  title={attributes.manufacturer}>

                {attributes.manufacturer}
              </p>
            : null}
          </div>
        </div>

        <div class={styles.messages}>
          {(attributes.display_low_stock_warning|0 &&
            product.isSalable &&
            product.stockManage &&
            product.stockQty  &&
            product.stockQty < (attributes.low_qty_below ? attributes.low_qty_below|0 : 30)) ?

            <InformationBar
                modifiers="white">

              {t("PRODUCT.LOW_STOCK")}
            </InformationBar>
          : null}

          {!product.isSalable ?
            <InformationBar
                modifiers="black">

              {t("PRODUCT.REPLACEMENT")}
            </InformationBar>
          : null}
        </div>
      </Link>
    );
  }
}
