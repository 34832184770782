import {
  APP_KEY,
  Routes,
  mkStore
}                     from "./shared";
import {
         defaultLang,
         missingKeys
}          from "helpers/locales";
import { h, render }            from "preact";
import {
         prefix,
         rejectOnHttpError,
         withCookies
}          from "fetch-utilities";

import { ConnectedRouter }      from "react-router-redux";
import CoreJs      from "core-js";
import { Provider }             from "preact-redux";
import { Route }                from "react-router";
import { WithGoogleAnalytics }  from "redux-enhancer-google-analytics";
import _base       from "theme/generic/_base.scss";
import _fonts      from "theme/generic/_fonts.scss";
import _helpers    from "theme/generic/_helpers.scss";
import _normalize  from "theme/generic/_normalize.scss";
import _rows       from "theme/objects/_rows.scss";
import _typography from "theme/generic/_typography.scss";
import config                   from "../config.js";
import { createBrowserHistory } from "history";
import fetch                    from "isomorphic-fetch";
import translation              from "translation";
import { watch } from "@crossroads/dev-server";
import whatInput                from "what-input";

// Application key
const root    = document.getElementById(APP_KEY);
const history = createBrowserHistory();
const client  = rejectOnHttpError(withCookies(prefix(fetch, config.apiHost.client)));

// Boot up watch-process in dev mode to make sure we reload
if (process.env.NODE_ENV !== "production") {
  watch({ port: 6001 });
}

if (process.env.NODE_ENV !== "production") {
  if (translation.LOCALE.ISO.IETF !== defaultLang.LOCALE.ISO.IETF) {
    const missingTranslationKeys = missingKeys(defaultLang, translation);
    const extraTranslationKeys   = missingKeys(translation, defaultLang);

    if (missingTranslationKeys) {
      console.error("The following keys are missing in your current translation:", JSON.stringify(missingTranslationKeys, null, 2));
    }

    if (extraTranslationKeys) {
      console.warn("The following keys exist in your current translation but not in the default:", JSON.stringify(extraTranslationKeys, null, 2));
    }
  }
}

window[APP_KEY] = mkStore(window[APP_KEY] && window[APP_KEY].getState ? window[APP_KEY].getState() : window[APP_KEY], history, client);

render(<div id={APP_KEY} class="root">
  <Provider store={window[APP_KEY]}>
    <WithGoogleAnalytics ga={window[APP_KEY].googleAnalytics}>
      <ConnectedRouter history={history}>
        <Route>
          <Routes translation={translation} />
        </Route>
      </ConnectedRouter>
    </WithGoogleAnalytics>
  </Provider>
</div>, null, root);
