// @flow

import { h }             from "preact";
import { PureComponent,
         ScrollTop }     from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import { connect }       from "preact-redux";
import Helmet            from "preact-helmet";
import { parseParams }   from "location-search-string";
import { stutter,
         fadeIn,
         slideLeft,
         slideUpFull,
         slideUp }       from "helpers/animations";

import { load   as loadOrder,
         set    as setOrder,
         LOADED as ORDER_LOADED } from "store-reducers/order";

import CheckmarkIcon from "icons/checkmark.svg";

import Button       from "components/Button";
import Wrapper      from "components/Wrapper";
import NumberedList from "components/NumberedList";

import AppFooter from "containers/AppFooter";
import NotFound  from "containers/NotFound";

const SPECIAL_CASE_SKUS = [391, 280, 259, 253, 250, 178];

@connect(
  state => ({
    customerOrder : state.customer.data.usage ? state.customer.data.usage.order : null,
    loaded        : state.order.state === ORDER_LOADED,
    order         : state.order.data,
  }),
  dispatch => ({
    loadOrder : id   => dispatch(loadOrder(id)),
    setOrder  : data => dispatch(setOrder(data)),
  })
)
export default class OrderSuccess extends PureComponent {
  static defaultProps = {
    order: {}
  }

  componentWillMount() {
    if (this.props.customerOrder) {
      this.props.setOrder(this.props.customerOrder);
      return;
    }

    const { orderId } = parseParams(this.props.location.search);

    if (orderId && !this.props.loaded) {
      this.props.loadOrder(orderId);
    }
  }

  render({ order, loaded }: Props, state: any, { t }: Context) {
    if (!loaded) {
      return null;
    } else if (!order.id) {
      return <NotFound />;
    }

    const isVirtual = order.quote.summary.virtual,
          isMixed   = order.quote.summary.hasVirtualItems;

    const hasSpecialCase = order.quote.items.some(i => SPECIAL_CASE_SKUS.indexOf(i.product.sku|0) !== -1);

    const KEY = hasSpecialCase ? "SPECIAL" : isVirtual ? "VIRTUAL" : isMixed ? "MIXED" : "NORMAL";

    return (
      <div class={styles.block}>
        {loaded ?
          <Wrapper
              element="section"
              class={styles.wrapper}>

            <div class={styles.contain}>
              <header
                  class={classNames(
                    styles.header,
                    styles.column,
                    "row--section",
                  )}>

                <div
                    style={stutter(1)}
                    class={classNames(
                      styles.checkmark,
                      fadeIn,
                    )}>

                  <CheckmarkIcon
                      class={styles.checkmarkIcon}
                  />
                </div>

                <div class={styles.headingArea}>
                  <h1 class={classNames("h3", styles.heading)}>
                    <span
                        style={stutter(2)}
                        class={classNames(
                          styles.primaryHeading,
                          slideUpFull,
                        )}>

                      {t("SUCCESS.HEADING")}
                    </span>

                    <span
                        style={stutter(3)}
                        class={classNames(
                          styles.subHeading,
                          "subheading",
                          slideUpFull,
                        )}>

                      {t("SUCCESS.ORDER_ID", { id: order.id })}
                    </span>
                  </h1>
                </div>
              </header>

              <div class={styles.column}>
              </div>
            </div>

            <div class={styles.contain}>
              <div class={styles.column}>
                <NumberedList>
                  <div
                      style={stutter(4)}
                      class={slideUp}>

                    <h2 class="h4">
                      {t(`SUCCESS.STEPS.${KEY}.1.HEADING`)}
                    </h2>

                    <p class="flat">
                      {t(`SUCCESS.STEPS.${KEY}.1.TEXT`, { email: order.email })}
                    </p>
                  </div>

                  <div
                      style={stutter(5)}
                      class={slideUp}>

                    <h2 class="h4">
                      {t(`SUCCESS.STEPS.${KEY}.2.HEADING`)}
                    </h2>

                    <p class="flat" dangerouslySetInnerHTML={{ __html: t(`SUCCESS.STEPS.${KEY}.2.TEXT`, { email: order.email })}} />
                  </div>

                  {(!isVirtual && !hasSpecialCase) ?
                    <div
                        style={stutter(6)}
                        class={slideUp}>

                      <h2 class="h4">
                        {t(`SUCCESS.STEPS.${KEY}.3.HEADING`)}
                      </h2>

                      <p class="flat">
                        {t(`SUCCESS.STEPS.${KEY}.3.TEXT`, { email: order.email })}
                      </p>
                    </div>
                  : null}

                  {(!isVirtual && !hasSpecialCase) ?
                    <div
                        style={stutter(7)}
                        class={slideUp}>

                      <h2 class="h4">
                        {t(`SUCCESS.STEPS.${KEY}.4.HEADING`)}
                      </h2>

                      <p class="flat">
                        {t(`SUCCESS.STEPS.${KEY}.4.TEXT`, { email: order.email })}
                      </p>
                    </div>
                  : null}
                </NumberedList>

                <section class="row--huge">
                  <h3 style={stutter(8)}
                      class={classNames("h4", "row", slideUp)}>

                    {t("SUCCESS.QUESTIONS.HEADING")}
                  </h3>

                  <Button
                      style={stutter(9)}
                      modifiers="ghost"
                      class={slideUp}
                      to={t("SUCCESS.QUESTIONS.URL")}>

                    {t("SUCCESS.QUESTIONS.LABEL")}
                  </Button>
                </section>
              </div>
            </div>
          </Wrapper>
        : null}

        <AppFooter
            style={stutter(13)}
            class={classNames(
              fadeIn,
              "row--section"
            )}
        />

        <ScrollTop />

        <Helmet
            title={t("SUCCESS.TITLE")}
        />
      </div>
    );
  }
}
