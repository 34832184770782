// @flow

import { h, Component }   from "preact";
import classNames         from "classnames";
import { connect }        from "preact-redux";
import Helmet             from "preact-helmet";
import styles             from "./styles.scss";
import { getDefaultMeta,
         getDefaultLink } from "helpers/getMetaData.js";

import AppHeader      from "containers/AppHeader";
import OldBrowser     from "containers/OldBrowser";
import SystemMessages from "containers/SystemMessages";

type Props = {
  children: Element,
  class?:   string,
};

@connect(
  state => ({
    location : state.router.location
  })
)
export default class App extends Component {
  props: Props;

  render({ children }: Props, state: any, { t }: Context) {
    return (
      <div class={styles.block}>
        <AppHeader />

        <div class={styles.wrap}>
          <main class={styles.main}>
            {children}
          </main>
        </div>

        <OldBrowser />

        <SystemMessages />

        <Helmet
            titleTemplate={`%s | ${t("META.TITLE")}`}
            htmlAttributes={{
              dir  : "ltr",
              lang : t("LOCALE.ISO.IETF")
            }}
            meta={getDefaultMeta(t)}
            link={getDefaultLink(t)}
            script={[
              { src : "/assets/scripts/modernizr.js" },
              { src : `https://use.typekit.net/${t("LOCALE.TYPEKIT_ID")}.js` },
              { innerHTML : "try{Typekit.load({ async: true });}catch(e){}" },
              { innerHTML : `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${t("LOCALE.GTM_ID")}');` },
            ]} />
      </div>
    );
  }
}
